import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CustomPopover } from '../../../cs/common/components/custom-popover';
import { ILimbusEgo } from '../../../common/model/graphql-types';
import { LimbusEgoPopover } from './lc-ego-popover';

interface IProps {
  employee: ILimbusEgo;
  enablePopover?: boolean;
  showLabel?: boolean;
  showTags?: boolean;
  showIcon?: boolean;
}

export const LimbusEgoCard: React.FC<IProps> = ({
  employee,
  showLabel,
  enablePopover,
  showTags
}) => {
  const image = employee.imageMed
    ? getImage(employee.imageMed.localFile.childImageSharp)
    : null;
  return (
    <CustomPopover
      enabled={enablePopover}
      customClass="character-hover-box hsr"
      popover={<LimbusEgoPopover character={employee} />}
    >
      <Link to={'/limbus-company/ego/' + employee.slug}>
        <div
          className={`avatar limbus ${employee.affinity} ${
            showLabel ? showLabel : ''
          }`}
        >
          {image && (
            <GatsbyImage
              className="disable-transition"
              image={image}
              alt={`${employee.name}`}
            />
          )}
        </div>
        {showLabel && <span className="emp-name">{employee.name}</span>}
        {showLabel && employee.isNew && showTags && (
          <span className="tag new">New</span>
        )}
      </Link>
    </CustomPopover>
  );
};
