import React from 'react';

import './lc-affinity.scss';

interface IProps {
  rarity: string;
}

export const LCRarity: React.FC<IProps> = ({ rarity }) => {
  return <div className={`lc-rarity rarity-${rarity}`}>{rarity}</div>;
};
