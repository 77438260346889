import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import './lc-affinity.scss';

interface IProps {
  affinity: string;
  value?: string;
  hideLabel?: boolean;
}

export const LCAffinity: React.FC<IProps> = ({
  affinity,
  value,
  hideLabel
}) => {
  return (
    <div className={`lc-affinity ${affinity}`}>
      {affinity === 'Envy' && (
        <>
          <StaticImage
            src="../../../../images/limbuscompany/icons/affinity_envy.png"
            alt={affinity}
          />
        </>
      )}
      {affinity === 'Gloom' && (
        <>
          <StaticImage
            src="../../../../images/limbuscompany/icons/affinity_gloom.png"
            alt={affinity}
          />
        </>
      )}
      {affinity === 'Gluttony' && (
        <>
          <StaticImage
            src="../../../../images/limbuscompany/icons/affinity_gluttony.png"
            alt={affinity}
          />
        </>
      )}
      {affinity === 'Lust' && (
        <>
          <StaticImage
            src="../../../../images/limbuscompany/icons/affinity_lust.png"
            alt={affinity}
          />
        </>
      )}
      {affinity === 'Pride' && (
        <>
          <StaticImage
            src="../../../../images/limbuscompany/icons/affinity_pride.png"
            alt={affinity}
          />
        </>
      )}
      {affinity === 'Sloth' && (
        <>
          <StaticImage
            src="../../../../images/limbuscompany/icons/affinity_sloth.png"
            alt={affinity}
          />
        </>
      )}
      {affinity === 'Wrath' && (
        <>
          <StaticImage
            src="../../../../images/limbuscompany/icons/affinity_wrath.png"
            alt={affinity}
          />
        </>
      )}
      {!hideLabel && <>{affinity}</>} {value && <>x{value}</>}
    </div>
  );
};
