import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ILimbusEgo } from '../../../common/model/graphql-types';
import { LCAffinity } from './lc-affinity';
import { LCRarity } from './lc-rarity';

interface IProps {
  character: ILimbusEgo;
}

export const LimbusEgoPopover: React.FC<IProps> = ({ character }) => {
  const image = character.imageSmall
    ? getImage(character.imageSmall.localFile.childImageSharp)
    : null;
  return (
    <div className="character-popover hsr">
      <div className="top hsr">
        <div className={`avatar limbus ${character.affinity}`}>
          {image && (
            <GatsbyImage
              className="disable-transition"
              image={image}
              alt={`${character.name}`}
            />
          )}
        </div>
        <p className="name">
          <span className="emp-name">{character.name}</span>{' '}
          {character.isNew && <span className="tag new">NEW!</span>}{' '}
        </p>
        <div className="type-class">
          <LCRarity rarity={character.rarity} />
          {character.cost.cost_1 && (
            <LCAffinity
              affinity={character.cost.cost_1}
              value={character.cost.cost_1_v}
              hideLabel
            />
          )}
          {character.cost.cost_2 && (
            <LCAffinity
              affinity={character.cost.cost_2}
              value={character.cost.cost_2_v}
              hideLabel
            />
          )}
          {character.cost.cost_3 && (
            <LCAffinity
              affinity={character.cost.cost_3}
              value={character.cost.cost_3_v}
              hideLabel
            />
          )}
          {character.cost.cost_4 && (
            <LCAffinity
              affinity={character.cost.cost_4}
              value={character.cost.cost_4_v}
              hideLabel
            />
          )}
        </div>
      </div>
    </div>
  );
};
